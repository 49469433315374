var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",class:{ 'main-hide-text': _vm.shrink }},[_c('div',{class:`sidebar-menu-content side-bar-menu-theme-${_vm.menuTheme}`,style:({
      width: _vm.shrink ? '60px' : _vm.menuWidth + 'px',
      overflow: _vm.shrink ? 'visible' : 'auto',
    })},[_c('shrinkable-menu',{attrs:{"shrink":_vm.shrink,"theme":_vm.menuTheme,"before-push":_vm.beforePush,"menu-list":_vm.menuList},on:{"on-change":_vm.handleSubmenuChange}},[(_vm.showLogo || _vm.fixNav)?_c('div',{staticClass:"logo-content",attrs:{"slot":"top"},slot:"top"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.shrink && (_vm.menuTheme != 'light' || _vm.mainTheme == 'darkMode')
          ),expression:"\n            !shrink && (menuTheme != 'light' || mainTheme == 'darkMode')\n          "}],attrs:{"src":require("@/assets/logo-white.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.shrink && _vm.menuTheme == 'light' && _vm.mainTheme != 'darkMode'),expression:"!shrink && menuTheme == 'light' && mainTheme != 'darkMode'"}],attrs:{"src":require("@/assets/logo-black.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.shrink),expression:"shrink"}],key:"min-logo",attrs:{"src":require("@/assets/logo-min.png")}})]):_vm._e()])],1),_c('div',{class:`main-header-content fix-nav-${_vm.fixNav}`,style:({
      paddingLeft: _vm.navPaddingLeft,
    })},[_c('div',{class:`main-header header-theme-${_vm.navTheme}`},[_c('div',{staticClass:"header-left"},[(_vm.fixNav && _vm.showLogo && !_vm.shrink)?_c('div',{staticClass:"fix-logo",staticStyle:{"width":"180px","background-color":"#444"}},[_c('img',{staticStyle:{"width":"28px","height":"28px"},attrs:{"src":require("@/assets/doors.png"),"alt":""}}),_c('span',{staticStyle:{"color":"#b6986d","font-size":"14px","margin":"0 0 0 5px","vertical-align":"middle"}},[_vm._v("地磅称重管理系统")])]):_c('div',{staticStyle:{"width":"60px","background-color":"#444","vertical-align":"middle"}}),(_vm.showFold)?_c('div',{staticClass:"header-navicon-content header-action",on:{"click":_vm.toggleClick}},[_c('Icon',{directives:[{name:"show",rawName:"v-show",value:(this.shrink),expression:"this.shrink"}],attrs:{"custom":"iconfont icon-menu-unfold","size":"20"}}),_c('Icon',{directives:[{name:"show",rawName:"v-show",value:(!this.shrink),expression:"!this.shrink"}],attrs:{"custom":"iconfont icon-menu-fold","size":"20"}})],1):_vm._e(),_c('div',{staticClass:"header-middle-content"},[(_vm.navType == 3)?_c('div',{staticClass:"main-breadcrumb"},[_c('breadcrumb-nav',{attrs:{"theme":_vm.navTheme,"currentPath":_vm.currentPath}})],1):_vm._e()])]),_c('div',{staticClass:"header-right-content"},[(_vm.showSearch)?_c('search',{attrs:{"theme":_vm.navTheme,"type":_vm.searchType}}):_vm._e(),(_vm.navType == 3)?_c('navApp',{attrs:{"currNav":_vm.currNav,"navList":_vm.navList,"type":_vm.navShowType},on:{"on-click":_vm.selectNav}}):_vm._e(),_c('message-tip'),_c('user')],1)])]),_c('div',{staticClass:"nav-tags",style:({
      paddingLeft: _vm.shrink ? '60px' : _vm.menuWidth + 'px',
    })},[(_vm.showTags)?_c('tags-page-opened',{attrs:{"pageTagsList":_vm.pageTagsList}}):_vm._e()],1),_c('div',{staticClass:"single-page-content",style:({
      left: _vm.shrink ? '60px' : _vm.menuWidth + 'px',
      top: _vm.showTags ? '100px' : '60px',
    })},[_c('div',{staticClass:"single-page"},[_c('keep-alive',{attrs:{"include":_vm.cachePage}},[_c('router-view')],1)],1),(_vm.showFooter)?_c('div',{staticClass:"main-page-footer-content"},[_c('Footer',{staticClass:"main-page-footer"})],1):_vm._e()]),_c('circleLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading-position"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }