<template>
  <div class="foot">
   
    <!-- <Row type="flex" justify="center" class="copyright">
      Copyright © 2023 艾博特（上海）电信科技有限公司
      <a href="https://beian.miit.gov.cn/" target="_blank" style="margin: 0 5px"
        >沪ICP备12035308号-4</a
      >
      {{ $t("rights") }}
    </Row> -->
  </div>
</template>

<script>
export default {
  name: "xboot-footer",
};
</script>

<style lang="less" scoped>
.foot {
  width: 100%;
  margin: 48px 0 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  .foot-item {
    margin: 0 auto;
    margin-bottom: 1vh;
    width: 60%;
    .item {
      color: rgba(0, 0, 0, 0.45);
    }
    :hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
